import { failureAction, moduleAction, prepareAction, requestAction, successAction } from 'store/prepareAction'

import { Theme } from './theme.types'

const themeAction = moduleAction('THEME')

export interface UIConfig {
  logo: string | null | undefined
  designConfig: Record<string, Record<string, Record<string, string>>>
  govSystemsConfig: { levelNames: string[]; modelName: string }
}

export const THEME_GET = themeAction('GET')
export const fetchThemeRequest = prepareAction(requestAction(THEME_GET))
export const fetchThemeSuccess = prepareAction<UIConfig>(successAction(THEME_GET))
export const fetchThemeFailure = prepareAction(failureAction(THEME_GET))

export interface UpdateThemePayload extends Pick<Theme, 'colors'> {
  logo: File | null
}

export const THEME_PUT = themeAction('PUT')
export const updateThemeRequest = prepareAction<UpdateThemePayload>(requestAction(THEME_PUT))
export const updateThemeSuccess = prepareAction<Theme>(successAction(THEME_PUT))
export const updateThemeFailure = prepareAction(failureAction(THEME_PUT))
