import { matchRealmFromUrl } from 'utils/realmUtils'

window.config = window.config || {
  noBackend: true,
  disableAuth: true,
  defaultRole: 'user',
}

export const defaultApi = 'http://localhost:3000/api'

// TODO: get it from backend
export const RBAC_LANGS = ['en', 'ja', 'de']

export const LANGUAGE_NAMES: Record<string, string> = {
  en: 'English',
  ja: '日本語',
  de: 'Deutsch',
}

const configuration: any = {
  BETA: process.env.BETA === 'true',
  Endpoints: {
    APPLICATIONS: defaultApi,
    CONCAR: defaultApi,
    KEYCLOAK: defaultApi,
    RBAC: defaultApi,
    JUPYTER: defaultApi,
    NEO4J: defaultApi,
    NIFI: defaultApi,
    PROJECTS_QUERY: defaultApi,
    PROJECTS_COMMAND: defaultApi,
    ADMIN_COMMAND: defaultApi,
    PLATFORM: defaultApi,
    PLATFORM_QUERY: defaultApi,
    USER_MANUAL: defaultApi,
    SSH_KEY_MANAGER: defaultApi,
    GRAFANA: defaultApi,
    MODEL_DEPLOYMENT: defaultApi,
    ASSESSMENTS: defaultApi,
    DATASOURCES: defaultApi,
    APPEARANCE_IMAGES: defaultApi,
    ASSETS: defaultApi,
    NOTIFICATIONS: defaultApi,
    GOVERNANCE: defaultApi,
  },
}

export const { noBackend, disableAuth, defaultRole } = window.config

Object.assign(configuration, window.config)

const realmMatch = matchRealmFromUrl(window.location.pathname)
if (realmMatch) {
  configuration.REALM = `grace-${realmMatch}`
}

// These will be undefined in noBackend mode
export const {
  REALM,
  AUTH = 'NATIVE',
  CLIENT_ID,
  SSH_HOST,
  DISABLE_TRACKING,
  FEEDBACK,
  PLATFORM_VERSION,
  PLATFORM_NAME,
  Endpoints,
  Projects,
  GPU,
  BETA,
} = configuration

export const noPlatformGPU = GPU === 'no'

export const isIdentityManagement = ['AZUREAD', 'COGNITO'].includes(AUTH)

export const DialogTypes = {
  // When using new dialog, add its type here
  WELCOME_SCREEN: 'WELCOME_SCREEN',
  ALERT_DIALOG: 'ALERT_DIALOG',
  ACTIVATE_WORKSPACE_DIALOG: 'ACTIVATE_WORKSPACE_DIALOG',
  EDIT_WORKSPACE_DIALOG: 'EDIT_WORKSPACE_DIALOG',
  CREATE_USER_DIALOG: 'CREATE_USER_DIALOG',
  CREATE_DEPLOYMENT_DIALOG: 'CREATE_DEPLOYMENT_DIALOG',
  EDIT_MODEL_DIALOG: 'EDIT_MODEL_DIALOG',
  EXPORT_MODEL_DIALOG: 'EXPORT_MODEL_DIALOG',
  EXPORT_APPLICATION_DIALOG: 'EXPORT_APPLICATION_DIALOG',
  EXPORT_PIPELINE_DIALOG: 'EXPORT_PIPELINE_DIALOG',
  EDIT_USER_DIALOG: 'EDIT_USER_DIALOG',
  EDIT_APPLICATION_DIALOG: 'EDIT_APPLICATION_DIALOG',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_GLOBAL_ROLES: 'CHANGE_GLOBAL_ROLES',
  ADD_SSH_KEY_DIALOG: 'ADD_SSH_KEY_DIALOG',
  CREATE_PROJECT_DIALOG: 'CREATE_PROJECT_DIALOG',
  SELECT_USERS: 'SELECT_USERS',
  ASSIGN_USER_TO_PROJECT: 'ASSIGN_USER_TO_PROJECT',
  PROJECT_SETTINGS: 'PROJECT_SETTINGS',
  REPORT_PROBLEM: 'REPORT_PROBLEM',
  SEND_FEEDBACK: 'SEND_FEEDBACK',
  CREATE_RULE_SET: 'CREATE_RULE_SET',
  CREATE_RULE_SET_FROM_TEMPLATE: 'CREATE_RULE_SET_FROM_TEMPLATE',
  EDIT_RULE_SET: 'EDIT_RULE_SET',
  CREATE_RULE: 'CREATE_RULE',
  IMPORT_RULE: 'IMPORT_RULE',
  EDIT_RULE: 'EDIT_RULE',
  SELECT_RULE: 'SELECT_RULE',
  SELECT_RULE_SET: 'SELECT_RULE_SET',
  SELECT_FOLDER: 'SELECT_FOLDER',
  CREATE_CERTIFICATE: 'CREATE_CERTIFICATE',
  EDIT_CERTIFICATE: 'EDIT_CERTIFICATE',
  CREATE_DATASOURCE: 'CREATE_DATASOURCE',
  EDIT_DATASOURCE: 'EDIT_DATASOURCE',
  IMPORT_DATA_CATALOG: 'IMPORT_DATA_CATALOG',
  IMPORT_CHOOSE_OBJECT_TYPE_DATA_CATALOG: 'IMPORT_CHOOSE_OBJECT_TYPE_DATA_CATALOG',
  CREATE_ENDPOINT: 'CREATE_ENDPOINT',
  EDIT_ENDPOINT: 'EDIT_ENDPOINT',
  TASK_FROM_FILE: 'TASK_FROM_FILE',
  EXIT_PIPELINE_BUILDER: 'EXIT_PIPELINE_BUILDER',
  WORKFLOW_LOGS: 'WORKFLOW_LOGS',
  DEPLOYED_MODEL_LOGS: 'DEPLOYED_MODEL_LOGS',
  SNAPSHOT_MODEL_LOGS: 'SNAPSHOT_MODEL_LOGS',
  APPLICATION_DEPLOYMENTS_LOGS: 'APPLICATION_DEPLOYMENTS_LOGS',
  APPLICATION_SNAPSHOT_LOGS: 'APPLICATION_SNAPSHOT_LOGS',
  INSTALL_PLATFORM_IMAGES_DIALOG: 'INSTALL_PLATFORM_IMAGES_DIALOG',
  CREATE_ASSET_DIALOG: 'CREATE_ASSET_DIALOG',
  EDIT_ASSET_DIALOG: 'EDIT_ASSET_DIALOG',
  INSTALL_ASSET_DIALOG: 'INSTALL_ASSET_DIALOG',
  ADD_TAGS: 'ADD_TAGS',
  CREATE_TAG: 'CREATE_TAG',
  SELECT_ASSESSMENT: 'SELECT_ASSESSMENT',
  SELECT_ASSESSMENT_TYPE: 'SELECT_ASSESSMENT_TYPE',
  SELECT_ASSESSMENT_STAGE: 'SELECT_ASSESSMENT_STAGE',
  SELECT_NEW_ASSESSMENT_TYPE: 'SELECT_NEW_ASSESSMENT_TYPE',
  SELECT_TEMPLATE_ASSESSMENT: 'SELECT_TEMPLATE_ASSESSMENT',
  SELECT_TEMPLATE_LOCKED_ASSESSMENT: 'SELECT_TEMPLATE_LOCKED_ASSESSMENT',
  CREATE_APPLICATION_IMAGE: 'CREATE_APPLICATION_IMAGE',
  EDIT_APPLICATION_IMAGE: 'EDIT_APPLICATION_IMAGE',
  CREATE_APPLICATION_DEPLOYMENT: 'CREATE_APPLICATION_DEPLOYMENT',
  CREATE_PROPERTY: 'CREATE_PROPERTY',
  EDIT_PROPERTY: 'EDIT_PROPERTY',
  PROPERTY_SETTINGS: 'PROPERTY_SETTINGS',
  SELECT_TOUR: 'SELECT_TOUR',
  RULE_SET_SCHEDULE: 'RULE_SET_SCHEDULE',
  CREATE_GOVERNANCE_TOOL: 'CREATE_GOVERNANCE_TOOL',
  CHANGE_RESOURCE: 'CHANGE_RESOURCE',
  CREATE_GOV_STREAM: 'CREATE_GOV_STREAM',
  EDIT_GOV_STREAM: 'EDIT_GOV_STREAM',
  RULE_CHOOSE_TYPE: 'RULE_CHOOSE_TYPE',
  CREATE_SLACK_CONNECTION: 'CREATE_SLACK_CONNECTION',
  EDIT_SLACK_CONNECTION: 'EDIT_SLACK_CONNECTION',
  SELECT_LABELS: 'SELECT_LABELS',
  CREATE_ASSESMENT_LABEL: 'CREATE_ASSESMENT_LABEL',
  SELECT_LCM_TYPE: 'SELECT_LCM_TYPE',
  SELECT_LCM_TEMPLATE: 'SELECT_LCM_TEMPLATE',
  CREATE_USER_TOKEN: 'CREATE_USER_TOKEN',
  CREATE_LIST: 'CREATE_LIST',
  EDIT_LIST: 'EDIT_LIST',
  CREATE_LIST_ENTRY: 'CREATE_LIST_ENTRY',
  EDIT_LIST_ENTRY: 'EDIT_LIST_ENTRY',
  CREATE_NEW_ASSESSMENT_VERSION: 'CREATE_NEW_ASSESSMENT_VERSION',
  EDIT_USER_AVATAR: 'EDIT_USER_AVATAR',
  CREATE_REALM: 'CREATE_REALM',
  CREATE_REALM_USER: 'CREATE_REALM_USER',
  CREATE_JOURNEY_STEP: 'CREATE_JOURNEY_STEP',
  EDIT_JOURNEY_STEP: 'EDIT_JOURNEY_STEP',
  SELECT_PREVIOUS_JOURNEY_STEP: 'SELECT_PREVIOUS_JOURNEY_STEP',
  ASSET_INSTALLATION_DETAILS: 'ASSET_INSTALLATION_DETAILS',
  MODEL_CARD_RULESETS_REPORT: 'MODEL_CARD_RULESETS_REPORT',
}

export const HttpResponseStatuses = {
  100: 'Continue',
  101: 'Switching Protocols',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a teapot",
  422: 'Unprocessable Entity',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  511: 'Network Authentication Required',
}

export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
}

export const DateFormats = Object.freeze({
  DEFAULT: 'DD/MM/YYYY',
  WITH_TIME: 'DD/MM/YYYY[\n]LT',
  WITH_TIME_24H: 'DD/MM/YYYY[\n]HH:mm:ss.SSS',
  DESCRIPTIVE: 'MMM Do, YYYY',
  TIME: 'LT',
  SHORT_TIME: 'HH:mm',
})

export const ReduxMethodStates = Object.freeze({
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
})

export enum ReduxMethodNameSuffixes {
  IN_PROGRESS = 'REQUESTED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

// UNIX-LIKE, because UNIX lets use hyphens (-), underscores (_) and $ at the end, which should not be used
// across Grace when it comes to naming, because of encoding troubles.
export const UNIX_LIKE_NAME_REGEXP = /^[a-z]([a-z0-9]{0,31})$/
export const UNIX_LIKE_NAME_FORBIDDEN_CHARS_REGEXP = /[^a-z0-9]/g
export const UNIX_LIKE_NAME_MAX_LENGTH = 32
export const PROJECT_UNIX_LIKE_NAME_MAX_LENGTH = 19
export const NO_SPECIAL_SYMBOLS_REGEXP = /^[\w\d\r\t\f -]*$/
// RFC 5322 email. This RE is more strict than yup's .email(), since it doesn't allow IDN emails
// copy-pasted from http://emailregex.com
export const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
export const SEMANTIC_VERSION_REGEXP = /^(0|[1-9]\d*)(\.(0|[1-9]\d*)){0,2}$/

export const ALPHABET_CHARACTERS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

export const DEFAULT_ASSESSMENT_VERSION = '1.0'

// Following `placement` from https://material-ui.com/api/popper/
export const PopperPlacements = {
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM: 'bottom',
  LEFT_END: 'left-end',
  LEFT_START: 'left-start',
  LEFT: 'left',
  RIGHT_END: 'right-end',
  RIGHT_START: 'right-start',
  RIGHT: 'right',
  TOP_END: 'top-end',
  TOP_START: 'top-start',
  TOP: 'top',
}

export const WorkspaceStatuses = Object.freeze({
  BEING_CREATED: 'BeingCreated',
  CREATED: 'Created',
  BEING_DELETED: 'BeingDeleted',
})

export const ResourceMins = Object.freeze({
  PROJECT_ACTIVATION_CPU: 0.5,
  CPU: 1,
  MEMORY: 0.5,
  STORAGE: 1,
  GPU: 1,
})
